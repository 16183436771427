var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"popup-dialog",staticStyle:{"height":"100px"},attrs:{"max-width":"900px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('link',{attrs:{"href":"https://fonts.googleapis.com/css?family=Fira Sans","rel":"stylesheet"}}),_c('v-card',{staticClass:"popup-card"},[_c('v-card-text',{staticClass:"text-center"},[_c('h3',{staticClass:"popup-h3"},[_c('span',{staticStyle:{"width":"200px"}},[_vm._v(" Tarefas e reuniões pendentes atribuídas a você ")])]),_c('v-row',[_c('v-data-table',{staticClass:"table-ticket pa-4 articulat-medium",attrs:{"headers":_vm.atribuidasVoceHeader,"items":_vm.tarefas,"height":"400","footer-props":{
                    itemsPerPageOptions:[-1]
                },"dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.data_hora_agendamento",fn:function(ref){
                var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTimeWithoutConvert")(value))+" ")]}},{key:"item.reuniao_tipo_id",fn:function(ref){
                var value = ref.value;
return [(value)?_c('span',[_vm._v(" "+_vm._s(_vm.getOcorrenciaTipoReuniao(value))+" ")]):_c('span',[_vm._v(" Não ")])]}},{key:"item.ocorrencia",fn:function(ref){
                var item = ref.item;
return [_c('open-new-tab-component',{attrs:{"propObjectLabel":item.entidade_nome,"propObjectId":item.entidade_id,"propToolName":item.entidade_tipo}})]}},{key:"item.created_at",fn:function(ref){
                var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(value))+" ")]}},{key:"item.descricao",fn:function(ref){
                var value = ref.value;
return [_c('text-expandable-component',[_vm._v(" "+_vm._s(value)+" ")])]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('button-component',{attrs:{"propTitle":"Ver todas as tarefas e reuniões pendentes","propClick":_vm.redirect,"propStyle":'color: white; background-color: #6400AC; font-size: 18px; text-transform: none;',"propLarge":true,"propClass":'articulat-bold pa-6 mt-2'},on:{"click":_vm.redirect}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }