import { __decorate } from "tslib";
import { mainStore, ocorrenciaTarefaStore } from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import { differenceInHours } from 'date-fns';
import OpenNewTabComponent from './OpenNewTabComponent.vue';
import ButtonComponent from './ButtonComponent.vue';
import TextExpandableComponent from './TextExpandableComponent.vue';
let TarefasPopupComponent = class TarefasPopupComponent extends Vue {
    constructor() {
        super(...arguments);
        this.dialog = false;
        this.atribuidasVoceHeader = [
            { text: 'Criado por', value: 'criado_por_nome' },
            { text: 'Criado em', value: 'created_at' },
            { text: 'Aluno', value: 'ocorrencia' },
            { text: 'Descrição', value: 'descricao' },
            { text: 'Reunião', value: 'reuniao_tipo_id' },
            { text: 'Agendada para', value: 'data_hora_agendamento' },
        ];
        this.tarefas = [];
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    async checkPopupDisplay() {
        this.dialog = false;
        const today = new Date();
        const savedUserId = localStorage.getItem('DRIVE-tarefas_digital_userID');
        const lastSeenDate = localStorage.getItem('DRIVE-tarefas_digital_popupLastSeen');
        const isUsuariosDiferentes = Number(savedUserId) !== this.userProfile.id;
        const diferencaEmHoras = differenceInHours(today, new Date(lastSeenDate));
        if (isUsuariosDiferentes || diferencaEmHoras >= 12) {
            this.tarefas = await ocorrenciaTarefaStore.getOcorrenciaTarefasByUserIdPendentes(this.userProfile.id);
            if (this.tarefas && this.tarefas.length > 0) {
                this.dialog = true;
            }
            localStorage.setItem('DRIVE-tarefas_digital_userID', this.userProfile.id.toString());
            localStorage.setItem('DRIVE-tarefas_digital_popupLastSeen', today.toISOString()); // Salva a data atual
        }
    }
    redirect() {
        this.dialog = false;
        const rota = this.$router.resolve({
            name: 'gestao-tarefas-e-reunioes-pendentes'
        });
        window.open(rota.href, '_blank');
    }
    mounted() {
        this.checkPopupDisplay();
    }
};
TarefasPopupComponent = __decorate([
    Component({
        components: {
            OpenNewTabComponent,
            ButtonComponent,
            TextExpandableComponent
        }
    })
], TarefasPopupComponent);
export default TarefasPopupComponent;
