import { __decorate } from "tslib";
import { mainStore } from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import { isAfter, startOfDay, differenceInHours } from 'date-fns';
let MarketingPopup = class MarketingPopup extends Vue {
    constructor() {
        super(...arguments);
        this.dialog = false;
        this.deadlineDate = '2025-04-16T00:00:00';
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get popupTitle() {
        return `📢 Atenção, Escolas! 📢`;
    }
    get deadlineDateFormatted() {
        const date = new Date(this.deadlineDate);
        return date.toLocaleDateString('pt-BR', {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
        });
    }
    checkPopupDisplay() {
        this.dialog = false;
        if (this.userProfile.company.escola_id === 2) {
            return;
        }
        const deadlineDate = startOfDay(new Date(this.deadlineDate));
        const today = new Date();
        if (isAfter(today, deadlineDate)) {
            return;
        }
        const savedUserId = localStorage.getItem('DRIVE-marketing_digital_userID');
        const lastSeenDate = localStorage.getItem('DRIVE-marketing_digital_popupLastSeen');
        const isUsuariosDiferentes = Number(savedUserId) !== this.userProfile.id;
        const diferencaEmHoras = differenceInHours(today, new Date(lastSeenDate));
        if (isUsuariosDiferentes || diferencaEmHoras >= 12) {
            this.dialog = true;
            localStorage.setItem('DRIVE-marketing_digital_userID', this.userProfile.id.toString());
            localStorage.setItem('DRIVE-marketing_digital_popupLastSeen', today.toISOString()); // Salva a data atual
        }
    }
    redirect() {
        this.dialog = false;
        window.open('https://www.knnconvention2025.com.br/store/knn-idiomas/product/knn-convention-2025/i/7410/coupon/pedagogico2', '_blank');
    }
    mounted() {
        this.checkPopupDisplay();
    }
};
MarketingPopup = __decorate([
    Component
], MarketingPopup);
export default MarketingPopup;
